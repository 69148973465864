<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('users.new')">
        <actions
          slot="actions"
          crud-links="users"
          :actions="actions"
        />
        <data-form
          :user="user"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'users-new',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      loading: false,
      actions: ['index'],
      user: { id: 0 },
    }
  },
  methods: {
    // FIXME
    routeBuilder () {
      return 'auth/register'
    },
    checkErrors (response) {
      /* const errors = response.data.errors */
      /* if (errors.email) {
        if (errors.email._isUnique) { */
      this.showToast(this.$t('notifications.errors.email_nonunique'), {
        icon: 'fa-times',
        position: 'top-right',
      })
      /*    }
      } */
    },
    async submit (user) {
      this.loading = true

      try {
        await this.$http.post(this.routeBuilder(), user)
      } catch (error) {
        // console.log('Error updating data', error.response)
        /* if (error.response.status === 422) { */
        this.checkErrors(error)
        /* } */
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.$router.push({ name: 'usersIndex' })
    },
  },
}
</script>
